import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './index.css';

export default function ItemList({ items_list, url_prefix, title, max_listings = 5, search = false, view_all = false }) {
    const [itemDetails, setItemDetails] = useState([]);

    useEffect(() => {
        async function getItems() {
            let itemUrls = items_list.keys().map(x => items_list(x));
            if (max_listings > 0) {
                itemUrls = itemUrls.slice(0, max_listings);
            }
            const itemText = await Promise.all(itemUrls.map(x => fetch(x).then(res => res.text())));
            const itemInfo = itemText.map(x => JSON.parse(x.split("\n")[0]));
            const itemContent = itemText.map(x => x.split("\n").slice(1).join("\n"));
            const itemDetails = items_list.keys().map(function (x, i) { return { url: `${url_prefix}/${x}`, itemInfo: itemInfo[i], itemContent: itemContent[i] } });
            setItemDetails(itemDetails);
        }
        getItems();
    }, [items_list, max_listings, url_prefix]);

    return (
        <section>
            <div className={`flex flex-row items-center mb-${view_all ? "6" : "9"} ${view_all ? "mt-9" : ""} gap-8`}>
                <h1 className={`text-${view_all ? "2" : "4"}xl font-extrabold`}>{title}</h1>

                {view_all ? <Link to={`/${url_prefix}s`} className="underline text-blue-400">View All</Link> : <></>}
            </div>
            <section>
                {itemDetails.sort(function (a, b) {
                    a = a.itemInfo.published.split('/').reverse().join('');
                    b = b.itemInfo.published.split('/').reverse().join('');
                    return a < b ? 1 : a > b ? -1 : 0;
                }).map((x, index) =>
                    <article className={`${index === 0 ? "" : "border-t-2"} p-4 rounded-md hover:bg-slate-200 border-slate-200 dark:hover:bg-slate-950 dark:border-slate-950`}>
                        <Link to={`/${x.url}`}>
                            <header className="flex-row flex items-center gap-3">
                                <img className={"max-w-16 max-h-16"} src={x.itemInfo.icon} alt={x.itemInfo.title} />
                                <div>
                                    <h2 className={"text-2xl font-extrabold"}>{x.itemInfo.title}</h2>
                                    <time>{x.itemInfo.published}</time>
                                    {view_all ? <></> : <p className="prose prose-siteScheme dark:prose-invert">{x.itemInfo.description} <a href={`/${url_prefix}s`}>Read More</a></p>}
                                </div>
                            </header>
                        </Link>
                    </article>
                )}
                {itemDetails.length === 0 ? <p>No {title} exist.</p> : <></>}
            </section>
        </section>
    );

}